.Home
    width: 100%
    height: 100vh
    background-color: #858585

    span
        color: green

    .memes
        opacity: 0.5
        width: 50px
        z-index: 1
        transition: 3s

        animation-name: shake
        animation-duration: 0.5s
        animation-iteration-count: infinite

        @keyframes shake
            0%
                transform: translate(0, 0)
            20%
                transform: translate(-5px, 0) rotate(-20deg)
            40%
                transform: translate(5px, 0) rotate(20deg)
            60%
                transform: translate(-5px, 0) rotate(-10deg)
            80%
                transform: translate(5px, 0) rotate(10deg)
            100%
                transform: translate(0, 0)

    .top
        position: relative
        z-index: 10
        display: flex

        .left-block
            width: 50%
            h1
                font-size: 39vh
                position: relative

                img
                    position: absolute
                    height: 34vh
                    bottom: 15%
                    left: 45%

                span
                    color: #000
                    position: relative

        .right-block
            width: 50%
            display: flex
            flex-direction: column
            justify-content: center

            a
                text-decoration: none
                color: #0000007a
                font-weight: 800
                font-size: 6vh
                transition: 0.3s

                &:hover
                    color: #000

    .center
        z-index: 10
        position: relative
        text-align: center

        h2
            font-size: 8vh
            word-spacing: 10vh
            margin-bottom: 5vh
            animation-name: textAnimation
            animation-duration: 3s
            animation-iteration-count: infinite
            animation-direction: alternate

            @keyframes textAnimation
                from
                    transform: rotate(-5deg)

                to
                    transform: rotate(5deg)

        a
            font-size: 6vh
            color: #000000
            font-weight: 800
            text-decoration: none

            h3
                font-size: 4vh

    .bottom
        position: relative
        z-index: 10
        display: flex
        justify-content: space-between

        a
            text-decoration: none
            font-size: 4vh
            color: #0000007a

            font-weight: 800

            &:hover
                color: #000

@media ( max-width: 600px  )
    .Home
        height: 130vh
        .top
            flex-direction: column
            text-align: center
            
            .left-block
                width: 100%
                transform: translate(-20%)
                h1
                    font-size: 200px
                    img
                        height: 160px
                        left: 65%
            
            .right-block
                width: 100%
                padding-bottom: 30px

